import Environment from "../Environment";

const B2cPolicies = {
  names: {
    externalUser: Environment.ExternalUserPolicyName,
    internalUser: Environment.InternalUserPolicyName,
  },
  authorities: {
    externalUser: {
      authority: Environment.MsalExternalUserAuthority,
    },
    internalUser: {
      authority: Environment.MsalInternalUserAuthority,
    },
  },
};

const B2cScope = Environment.MsalScope;

export { B2cPolicies, B2cScope };
