import React, { FunctionComponent } from 'react';
import { PageHeader } from 'common.ui';
import './Page.scss';

interface IProps {
  header?: string;
  stepCurrent?: number;
  stepTotal?: number;
}

const Page: FunctionComponent<IProps> = ({ header, stepCurrent, stepTotal, children }) => {
  return (
    <div className='page-container'>
      {header && <PageHeader value={header} stepCurrent={stepCurrent} stepTotal={stepTotal} />}
      {children}
    </div>
  );
};

export default Page;
