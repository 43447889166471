import React from "react";
import { useMsal } from "../auth/MsalContext";
import Page from "../components/Page";

const ProfilePage: React.FC = () => {
  const { user } = useMsal();
  const str = JSON.stringify(user?.idTokenClaims, null, "\n");

  return (
    <Page header={'Profile page'}>
      <h2>Token claims</h2>
      {user?.idTokenClaims ? (
        <p>
          <pre>{str}</pre>
        </p>
      ) : (
        "No token data"
      )}
    </Page>
  );
};

export default ProfilePage;
