import React from 'react';
import styles from './MainContent.scss';

type IProp = {
  centered?: boolean;
  children: React.ReactElement;
};

export function MainContent({ centered, children }: IProp) {
  return (
    <React.Fragment>
      <div className={styles['main-container']}>
        <div
          className={`${styles.content} ${
            centered ? styles['content-centered'] : styles['content-max']
          }`}
        >
          {children}
        </div>
      </div>
    </React.Fragment>
  );
}
