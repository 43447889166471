import { Configuration, LogLevel } from "@azure/msal-browser";
import Environment from "../Environment";
import { B2cPolicies } from "./AzureB2cConfig";

const MsalConfig = {
  auth: {
    knownAuthorities: [
      B2cPolicies.authorities.externalUser.authority,
      B2cPolicies.authorities.internalUser.authority,
    ],
    authority: B2cPolicies.authorities.externalUser.authority,
    clientId: Environment.MsalClientId,
    redirectUri: window.location.origin,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      piiLoggingEnabled: true,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
} as Configuration;

export default MsalConfig;
