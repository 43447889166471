import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import "common.ui/dist/index.css";
import { MsalProvider } from "./auth/MsalContext";
import { Routes } from "./routing/Routes";

declare global {
  interface Window {
    spaSettings: SpaSettings;
  }
}

function App() {
  return (
    <Router>
      <MsalProvider>
        <Routes />
      </MsalProvider>
    </Router>
  );
}

export default App;
