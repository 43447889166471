import React from "react";
import { Route, Switch } from "react-router-dom";
import { useMsal } from "../auth/MsalContext";
import { withAuth } from "../auth/withAuth";
import HomePage from "../pages/HomePage";
import NotFoundPage from "../pages/NotFoundPage";
import ProfilePage from "../pages/ProfilePage";
import TokenDumpPage from "../pages/TokenDumpPage";
import { RoutePaths } from "./RoutePaths";
import Login from "../components/Login";
import { Header, MainContent } from "common.ui";
import Environment from "../Environment";

import logoImage from "../assets/images/MdirLogo_Main.svg";
import accountImage from "../assets/images/person-header.png";
import ClearCookies from "../components/ClearCookies";

const auth = (c: any) => {
  return () => {
    const { isB2cPolicySet, authInProgress } = useMsal();
    if (authInProgress) return <div>Loading...</div>;

    if (!isB2cPolicySet()) {
      return <Login />;
    }
    return <div>{withAuth(c)(c)}</div>;
  };
};

export const Routes: React.FC = () => {
  const { authInProgress, isAuthenticated, user, logout } = useMsal();
  return (
    <>
      <Header
        centered
        applicationName={Environment.AppName}
        logoImage={logoImage}
        accountImage={accountImage}
        authInProgress={authInProgress}
        isAuthenticated={isAuthenticated}
        activeUser={user}
        logout={logout}
      />

      <MainContent centered>
        <Switch>
          <Route
            exact
            key={RoutePaths.home}
            path={RoutePaths.home}
            component={auth(HomePage)}
          />

          <Route
            exact
            key={RoutePaths.profile}
            path={RoutePaths.profile}
            component={auth(ProfilePage)}
          />

          <Route
            exact
            key={RoutePaths.tokenDump}
            path={RoutePaths.tokenDump}
            component={auth(TokenDumpPage)}
          />

          <Route exact key={RoutePaths.clearCookies} path={RoutePaths.clearCookies} component={ClearCookies} />

          {/* 404 route */}
          <Route component={NotFoundPage} />
        </Switch>
      </MainContent>
    </>
  );
};
