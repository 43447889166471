import * as React from "react";
import MsalAuthWrapper from "./MsalAuthWrapper";

export const withAuth = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.FunctionComponent<P> => {
  // tslint:disable-next-line: no-shadowed-variable
  const withAuthentication: React.FunctionComponent = (props: any) => {
    withAuthentication.displayName = `withAuthentication(${
      WrappedComponent.displayName || WrappedComponent.name
    }`;

    return (
      <MsalAuthWrapper>
        <WrappedComponent {...props} />
      </MsalAuthWrapper>
    );
  };

  return withAuthentication;
};
