import React from "react";
import { useMsal } from "../auth/MsalContext";
import Page from "../components/Page";
import { ITokenClaimsModel } from "../models/TokenClaimsModel";

const HomePage: React.FC = () => {
  const { user } = useMsal();
  const [tokenClaims] = React.useState<
    ITokenClaimsModel | undefined
  >(user?.idTokenClaims as ITokenClaimsModel);

  return (
    <Page header={'Brukerprofil'}>
      <p>
        <div>
          <b>Navn:</b> {tokenClaims?.name}
        </div>
        <div>
          <b>E-post:</b> {tokenClaims?.email}
        </div>
        <div>
          <b>Applikasjon:</b> {tokenClaims?.app}
        </div>
        <div>
          <b>Brukertype:</b>{" "}
          {tokenClaims?.user_type === "Internal"
            ? "Intern bruker"
            : "Ekstern bruker"}
        </div>
        <div>
          <b>Roller:</b>
          {tokenClaims?.roles && tokenClaims.roles.length > 0 ? (
            <ul>
              {tokenClaims.roles.map((x) => (
                <li>{x}</li>
              ))}
            </ul>
          ) : null}
        </div>
        <div>
          <strong>Tilganger:</strong>
          {tokenClaims?.permissions && tokenClaims.permissions.length > 0 ? (
            <ul>
              {tokenClaims.permissions.map((x) => (
                <li>{x}</li>
              ))}
            </ul>
          ) : null}
        </div>
      </p>
    </Page>
  );
};

export default HomePage;
