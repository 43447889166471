import React, { useState, useEffect } from "react";
import * as msal from "@azure/msal-browser";
import { useRouteMatch, useHistory } from "react-router-dom";
import { AuthService } from "./AuthService";
import { B2cPolicies } from "./AzureB2cConfig";
import { MsalNavigationClient } from "./MsalNavigationClient";

export interface IMsalContext {
  isAuthenticated: boolean;
  user: msal.AccountInfo | undefined;
  login: () => Promise<void>;
  logout: () => void;
  refreshToken: () => void;
  setB2cPolicy: (policyName: string, lang: string) => void;
  isB2cPolicySet: () => boolean;
  authInProgress: boolean;
}

export const MsalContext = React.createContext<IMsalContext | undefined>(
  undefined
);

export const useMsal = () => {
  const context = React.useContext(MsalContext);
  if (context === undefined) {
    throw new Error("useMsal must be used within a MsalProvider");
  }
  return context;
};

export const MsalProvider = ({ children }: { children: any }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<msal.AccountInfo | undefined>();
  const [authInProgress, setAuthInProgress] = useState<boolean>(true);
  const [lang, setLang] = useState<string>();
  const [b2cAuthority, setB2cAuthority] = useState<string | undefined>(
    undefined
  );
  const passwordResetMatch = useRouteMatch("/password-reset");
  const { push } = useHistory();
  AuthService.setNavigationClient(new MsalNavigationClient(push));

  useEffect(() => {
    const authenticate = async () => {
      setAuthInProgress(true);
      try {
        await AuthService.handleRedirect();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("HandleRedirect: ", e);
      } finally {
        setUser(AuthService.getUser());
        setIsAuthenticated(AuthService.isLoggedIn());
        if (AuthService.isLoggedIn())
          setB2cAuthority(
            AuthService.mapHomeAccountIdToAuthority(
              AuthService.getUser()?.homeAccountId
            )
          );

        AuthService.setMsalCallback((event: msal.EventMessage) => {
          onMsalEvent(event);
        });

        setAuthInProgress(false);
      }
    };

    authenticate();
  }, [passwordResetMatch]);

  function onMsalEvent(event: msal.EventMessage) {
    // handle only acquire token events in the UI as login/logout uses redirect
    if (event.eventType === msal.EventType.ACQUIRE_TOKEN_FAILURE) {
      setIsAuthenticated(AuthService.isLoggedIn());
      setB2cAuthority(undefined);
      setUser(undefined);
    }

    if (event.eventType === msal.EventType.ACQUIRE_TOKEN_SUCCESS) {
      setUser(AuthService.getUser());
      setIsAuthenticated(AuthService.isLoggedIn());
      if (AuthService.isLoggedIn())
        setB2cAuthority(
          AuthService.mapHomeAccountIdToAuthority(
            AuthService.getUser()?.homeAccountId
          )
        );
    }
  }

  const setB2cPolicy = (policyName: string, lang: string) => {
    let authority: string | undefined;

    if (policyName === B2cPolicies.names.internalUser)
      authority = B2cPolicies.authorities.internalUser.authority;
    else if (policyName === B2cPolicies.names.externalUser)
      authority = B2cPolicies.authorities.externalUser.authority;

    if (authority === undefined)
      throw Error(`${policyName} is not a valid B2C policy name.`);

    setLang(lang);
    setB2cAuthority(authority);
  };

  const isB2cPolicySet = () => {
    return b2cAuthority !== undefined;
  };

  const login = async () => {
    if (b2cAuthority === undefined) throw Error(`B2C policy is not set.`);

    setAuthInProgress(true);
    await AuthService.signInRedirect(b2cAuthority, undefined, lang);
  };

  const logout = async () => {
    setAuthInProgress(true);
    await AuthService.signOut();
  };

  const refreshToken = () => AuthService.getAccessTokenSilent(true);

  return (
    <MsalContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        refreshToken,
        setB2cPolicy,
        isB2cPolicySet,
        authInProgress,
      }}
    >
      {children}
    </MsalContext.Provider>
  );
};
