/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import {
  AccountInfo,
  AuthenticationResult,
  BrowserAuthError,
  BrowserAuthErrorMessage,
  Configuration,
  EndSessionRequest,
  EventMessage,
  EventType,
  INavigationClient,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { AuthStateStorageService } from "common.ui";
import MsalConfig from "./MsalConfig";
import { B2cPolicies, B2cScope } from "./AzureB2cConfig";
import { IdTokenClaims } from "./IdTokenClaims";

class AuthService {
  private msalConfig: Configuration;

  private externalCallbackId: string | undefined;

  private MsalClient: PublicClientApplication;

  private openIdScopes: string[];

  constructor() {
    this.msalConfig = MsalConfig;
    this.openIdScopes = [B2cScope, "openid", "profile"];
    this.MsalClient = new PublicClientApplication(this.msalConfig);

    this.initActiveAccount();

    this.MsalClient.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        AuthStateStorageService.setMsalError(undefined);
        this.handleLoginSuccess(event);
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        this.handleError(event.error?.message);
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        AuthStateStorageService.setMsalError(
          "Access token silent acquire failed."
        );
        this.handleAcquireTokenFailure(event);
      }

      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        AuthStateStorageService.setMsalError(undefined);
      }
    });
  }

  setMsalCallback(onAcquireTokenFailed: (event: EventMessage) => void) {
    if (this.externalCallbackId) {
      this.MsalClient.removeEventCallback(this.externalCallbackId);
      this.externalCallbackId = undefined;
    }

    this.externalCallbackId =
      this.MsalClient.addEventCallback((event: EventMessage) => {
        onAcquireTokenFailed(event);
      }) ?? undefined;
  }

  setNavigationClient(navClient: INavigationClient) {
    this.MsalClient.setNavigationClient(navClient);
  }

  async signInRedirect(
    authority: string,
    redirectUri: string | undefined = undefined,
    lang: string | undefined = undefined
  ): Promise<void> {
    console.log("Calling login redirect");
    if (redirectUri)
      this.MsalClient.loginRedirect({
        authority,
        scopes: this.openIdScopes,
        redirectUri,
        extraQueryParameters: {
          ui_locales: lang
        },
      } as RedirectRequest);
    else
      this.MsalClient.loginRedirect({
        authority,
        scopes: this.openIdScopes,
        extraQueryParameters: {
          ui_locales: lang
        },
      } as RedirectRequest);
  }

  async handleRedirect(): Promise<void> {
    await this.MsalClient.handleRedirectPromise();
  }

  async signOut(): Promise<void> {
    const account = this.getUser();
    await this.MsalClient.logoutRedirect({
      account,
    } as EndSessionRequest);
  }

  async getAccessTokenSilent(
    forceRefresh: boolean = false
  ): Promise<string | undefined> {
    const account = this.getUser();
    const authority = this.mapHomeAccountIdToAuthority(account?.homeAccountId);

    const authResponse = await this.MsalClient.acquireTokenSilent({
      authority,
      account,
      scopes: [B2cScope],
      forceRefresh,
    } as SilentRequest);
    return authResponse.accessToken;
  }
  mapHomeAccountIdToAuthority(
    homeAccountId: string | undefined
  ): string | undefined {
    let authority: string | undefined;

    if (
      homeAccountId
        ?.toLowerCase()
        .indexOf(B2cPolicies.names.internalUser.toLowerCase()) !== -1
    )
      authority = B2cPolicies.authorities.internalUser.authority;
    else if (
      homeAccountId
        ?.toLowerCase()
        .indexOf(B2cPolicies.names.externalUser.toLowerCase()) !== -1
    )
      authority = B2cPolicies.authorities.externalUser.authority;

    return authority;
  }

  getUser(): AccountInfo | undefined {
    return this.MsalClient.getActiveAccount() ?? undefined;
  }

  isLoggedIn(): boolean {
    if (AuthStateStorageService.getMsalError()) return false;
    if (this.getUser()) return true;

    return false;
  }

  clearStorageValues() {
    AuthStateStorageService.clearMsalValues();
  }

  private initActiveAccount() {
    const accounts = this.MsalClient.getAllAccounts();
    const initialActiveAccount = this.getInitialActiveAccount(accounts);

    if (initialActiveAccount)
      this.MsalClient.setActiveAccount(initialActiveAccount);
  }

  private getInitialActiveAccount(
    accounts: AccountInfo[]
  ): AccountInfo | undefined {
    if (accounts) {
      return accounts.sort(
        (x, y) =>
          (y.idTokenClaims as IdTokenClaims).auth_time -
          (x.idTokenClaims as IdTokenClaims).auth_time
      )[0];
    }

    return undefined;
  }

  private getAccessTokenRedirect() {
    console.log("Calling access token redirect");
    const account = this.getUser();
    const authority = this.mapHomeAccountIdToAuthority(account?.homeAccountId);

    if (authority === undefined)
      throw new Error("Policy not found. Error redirecting user to login.");

    this.MsalClient.acquireTokenRedirect({
      authority,
      scopes: [B2cScope],
      account,
    } as RedirectRequest);
  }

  private handleLoginSuccess(event: EventMessage) {
    const { account } = event.payload as AuthenticationResult;
    this.MsalClient.setActiveAccount(account);
  }

  private handleAcquireTokenFailure(event: EventMessage) {
    let promptUserForLogin = false;

    if (event.error instanceof InteractionRequiredAuthError) {
      promptUserForLogin = true;
    } else if (
      event.error instanceof BrowserAuthError &&
      event.error.errorCode ===
        BrowserAuthErrorMessage.silentSSOInsufficientInfoError.code
    ) {
      console.log(
        `SSO failed error desc: ${BrowserAuthErrorMessage.silentSSOInsufficientInfoError.desc}`
      );
      promptUserForLogin = true;
    } else {
      this.handleError(event.error);
    }

    if (promptUserForLogin) {
      this.getAccessTokenRedirect();
    }
  }

  private handleError(error: any) {
    console.log(error);
  }
}

const instance = new AuthService();
export { instance as AuthService };
