import React from "react";
import Page from "../components/Page";

const NotFoundPage: React.FC = () => {
  return (
    <Page header={'Not found'}>
      <h1>Page not found - 404</h1>
      <h1>:(</h1>
    </Page>
  );
};

export default NotFoundPage;
