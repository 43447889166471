import { AuthService } from '../auth/AuthService';
import React, { useEffect } from 'react';
import Page from './Page';

const ClearCookies = () => {
  useEffect(() => {
    AuthService.clearStorageValues();
  }, []);

  return (
    <Page>
      <p>Nettleser informasjon er fjernet.</p>
    </Page>
  );
};

export default ClearCookies;
