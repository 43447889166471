import React from "react";
import { useMsal } from "../auth/MsalContext";
import { B2cPolicies } from "../auth/AzureB2cConfig";
import { Button, Dropdown, SelectOption } from "common.ui";

const Login: React.FC = () => {
  //TODO: Introduce i18n stuff here in the next iteration
  
  const [lang, setLang] = React.useState<string>("en");
  const { setB2cPolicy } = useMsal();
  const langs: SelectOption[] = [
    { id: "en", text: "English" },
    { id: "nb", text: "Norsk" },
  ];
  return (
    <>
      <h1>{lang === "en" ? "Log in as:" : "Logg inn som:"}</h1>
      <div>
        <Dropdown
          options={langs}
          id="langSelector"
          labelText={lang === "en" ? "Login flow language" : "Påloggingsflyt språk"}
          onChange={(e: any) => {
            const val = e.target.value as string;
            setLang(val);
          }}
        />
      </div>

      <p>
        <Button
          id="btnLoginInternalEmployee"
          type="button"
          onClick={async () => setB2cPolicy(B2cPolicies.names.internalUser, lang)}
          text={lang === "en" ? "Internal employee at Miljødirektoratet" : "Intern ansatt i Miljødirektoratet"}
          styleType="light"
        />
        <br />
        <Button
          id="btnLoginExternalUser"
          type="button"
          onClick={async () => setB2cPolicy(B2cPolicies.names.externalUser, lang)}
          text={lang === "en" ? "External partner" : "Ekstern partner"}
          styleType="light"
        />
      </p>
    </>
  );
};

export default Login;
