import React from "react";
import { useMsal } from "../auth/MsalContext";
import Page from "../components/Page";

const TokenDumpPage: React.FC = () => {
  const { user, refreshToken } = useMsal();
  const str = JSON.stringify(user?.idTokenClaims, null, "\t");

  return (
    <Page header={'Token claims'}>
      {user?.idTokenClaims ? <pre>{str}</pre> : "No token data"}
      <button onClick={refreshToken}>Refresh token</button>
    </Page>
  );
};

export default TokenDumpPage;
